@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.root {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include breakpoints.media(mobile-landscape) {
    flex-direction: row;
    padding-bottom: 5rem;
  }
  @include breakpoints.media(tablet-landscape) {
    justify-content: center;
    padding-bottom: 10rem;
  }
}

.imageWrapper {
  display: flex;
}

.contentWrapper {
  display: flex;
  flex-direction: column;

  @include breakpoints.media(mobile-landscape) {
    margin-top: 1.875rem;
  }
  @include breakpoints.media(tablet) {
    margin-left: spacing.$spacing-24;
    margin-top: spacing.$spacing-24;
  }
  @include breakpoints.media(tablet-landscape) {
    margin-top: 3.125rem;
  }
}

.icon {
  width: 8.5rem;
  height: 8.5rem;

  @include breakpoints.media(mobile-landscape) {
    width: 9.125rem;
    height: 9.125rem;
  }
  @include breakpoints.media(tablet) {
    width: 9.875rem;
    height: 9.875rem;
  }
  @include breakpoints.media(tablet-landscape) {
    width: 13.75rem;
    height: 13.75rem;
  }
}

.title {
  @include typography.default-2xl-heavy;
  text-align: center;
  margin-top: 0;

  @include breakpoints.media(mobile-landscape) {
    @include typography.default-l-heavy;
    text-align: left;
    margin-bottom: 0;
  }
  @include breakpoints.media(tablet) {
    @include typography.default-2xl-heavy;
  }
  @include breakpoints.media(desktop) {
    @include typography.default-5xl-heavy;
  }
}

.text {
  @include typography.default-s;
  text-align: center;
  margin-top: 0;
  margin-bottom: spacing.$spacing-24;

  @include breakpoints.media(mobile-landscape) {
    text-align: left;
    margin-top: spacing.$spacing-8;
  }
  @include breakpoints.media(tablet-landscape) {
    margin-top: spacing.$spacing-16;
  }
  @include breakpoints.media(desktop) {
    @include typography.default-l;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;

  @include breakpoints.media(mobile-landscape) {
    justify-content: left;
  }
}
